<template>
    <div>
        <a v-if="hasAchor()" v-bind:href="anchor" v-on:click="toggleShowHide()"><slot name="header"></slot></a>
        <div v-if="!hasAchor()" style="cursor:pointer" v-on:click="toggleShowHide()"><slot name="header"></slot></div>
        <slot name="extra"></slot>
        <div class="modal" v-bind:class="{ 'is-active' : isVisible }">
            <div class="modal-shade" v-on:click="toggleShowHide()"></div>
            <div class="modal-inner">
                <div class="modal__close" v-on:click="toggleShowHide()" title="Stäng"><i class="icon-close"></i></div>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <slot name="body"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        props: {
            isVisibleInit: {
                type: Boolean,
                required: false,
                default: false
            },
            anchor: {
                type: String
            }
        },
        data() {
            return {
                isVisible: false
            }
        },
        created() {
            this.isVisible = this.isVisibleInit;
        },
        methods: {
            toggleShowHide() {
                this.isVisible ? this.isVisible = false : this.isVisible = true;
            },

            hasAchor() {
                return this.anchor !== "" && this.anchor !== undefined ? true : false;
            }
        }
    }
</script>